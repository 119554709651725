import baseComponent from "@/scripts/baseComponent";
import authApi from '@/api/common/auth';

export default {

    extends: baseComponent,

    props: {
        element: {
            type: String,
            default: null
        },
    },

    data() {
        return {
            confirmText: '',
            request: {
                description: '',
            }
        };
    },

    computed: {

    },

    created() {

    },

    mounted() {

    },

    methods: {

        // Close this pop-up
        closeThisPopup() {
            this.listData = {};
            this.closePopup('user-report-ip');
        },

        // Report
        report() {
            this.isLoading = true;
            this.request.ip = this.element;
            this.request.deviceInformation = JSON.stringify(this.getInformationClient());
            authApi.reportIpAddress(this.request)
                .then((res) => {
                    try {
                        this.showSuccessToast(`Report IP address successful !`);
                        setTimeout(() => {
                            this.closePopup('user-report-ip');
                        }, 500);
                    } catch (error) {
                        console.log("changeSettingsUser -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 500);
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            // if (this.$route.path !== '/login') {
                            //     setTimeout(() => {
                            //         location.href = "/login";
                            //     }, 2000);
                            // }
                        } else {
                            console.log("changeSettingsUser -> error", error)
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 500);
                });
        }
    }
} 